import { styled } from "@mui/system";

const TextContent = styled("div")(({ theme, isNotMobileScreen }) => ({
  color: theme.palette.neutral.light,
  fontSize: "22px",
  padding: isNotMobileScreen ? "30px 20vw" : "45px 4vw", // 5vh, 7vh
  marginTop: isNotMobileScreen ? "108px" : "72px", // 10vh : 7vh
  minHeight: isNotMobileScreen ? "79vh" : "77vh",
  //textAlign: "justify",
}));

export default TextContent;
