import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function PageTitle({ title }) {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();

  return (
    <>
      {isNotMobileScreen ? (
        <Typography
          variant="h1"
          color={palette.neutral.main}
          sx={{ [isNotMobileScreen ? "margin" : "marginBottom"]: "0" }}
        >
          {title.toUpperCase()}
        </Typography>
      ) : (
        <Typography
          variant="h2"
          color={palette.neutral.main}
          sx={{ [isNotMobileScreen ? "margin" : "marginBottom"]: "0" }}
        >
          {title.toUpperCase()}
        </Typography>
      )}
    </>
  );
}
