import { useTheme } from "@mui/material";
import React, { useRef } from "react";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ButtonNavbar({ text, setDrawerOpen = false }) {
  const { palette } = useTheme();
  const buttonRef = useRef(null);

  const mode = useSelector((state) => state.mode);

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const StyledDiv = styled("div")(({ theme }) => ({
    margin: "1.5rem",
    borderLeft: `1px solid ${
      mode === "dark" ? palette.neutral.main : palette.neutral.medium
    }`,
    borderRight: `1px solid ${
      mode === "dark" ? palette.neutral.main : palette.neutral.medium
    }`,
    "&:hover": {
      backgroundColor:
        mode === "dark" ? hexToRGBA("#404040", 0.6) : hexToRGBA("#bdbdbd", 0.6),
    },
  }));

  const StyledSpan = styled("p")(({ theme }) => ({
    padding: 0,
    color: palette.neutral.light,
    margin: 0,
    transition: "color 0.3s ease",
    "&::before": {
      content: "''",
      position: "absolute",
      display: "block",
      width: "50%",
      height: "2px",
      bottom: "25%",
      left: "25%",
      backgroundColor: palette.primary.main,
      transform: "scaleX(0)",
      transition: "transform 0.3s ease",
    },
    "&:hover::before": {
      transform: "scaleX(0.7)",
    },
  }));

  let hyperlink = text;
  if (text === "über_uns") hyperlink = "about_us";
  if (text === "kontakt") hyperlink = "contact";
  if (text === "projekte") hyperlink = "projects";

  const handleClick = (e) => {
    if (setDrawerOpen !== false) {
      setTimeout(() => {
        setDrawerOpen(false);
      }, 400);
    }
    const button = buttonRef.current;

    let x = e.clientX - button.offsetLeft;
    let y = e.clientY - button.offsetTop;

    let ripple = document.createElement("span");
    ripple.style.backgroundColor = palette.primary.main;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;

    button.appendChild(ripple);

    setTimeout(function () {
      ripple.remove();
    }, 400);
  };

  return (
    <StyledDiv
      className="navbarButton"
      ref={buttonRef}
      onClick={(e) => handleClick(e)}
    >
      <NavLink
        id="navLinkUnderline"
        to={`/${hyperlink === "home" ? "" : hyperlink}`}
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => (
          <StyledSpan id={isActive ? "active" : ""}>
            {text.replace(/_/g, " ")}
          </StyledSpan>
        )}
      </NavLink>
    </StyledDiv>
  );
}
