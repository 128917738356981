import React, { useState, useEffect } from "react";
import { IconButton, useMediaQuery } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

function Carousel({
  images,
  boat = { type: "user", hullNumber: "generated" },
  loading,
  setLoading,
  imgDisplaySize,
}) {
  const { palette } = useTheme();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!images || images.length === 0) {
        return;
      }
      if (event.key === "ArrowLeft") {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
      } else if (event.key === "ArrowRight") {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [images]); // Include images in the dependency array to ensure the latest state is used

  if (images.length === 0) {
    return null; // or some message indicating no images
  }

  return (
    <div style={{ position: "relative", maxWidth: "100%", overflow: "hidden" }}>
      {images.length > 1 && (
        <IconButton
          onClick={handlePrevClick}
          style={{ position: "absolute", top: "50%", left: "5px", zIndex: 1 }}
          color="primary"
          sx={{ backgroundColor: "rgba(107, 107, 107, 0.4)" }}
        >
          <ChevronLeft />
        </IconButton>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: imgDisplaySize === "medium" ? "center" : "",
        }}
      >
        <img
          style={{
            display: "block",
            borderRadius: "0.25rem",
            width:
              (imgDisplaySize === "large" && "100%") ||
              (imgDisplaySize === "medium" && "70%") ||
              (imgDisplaySize === "small" && isNotMobileScreen
                ? "200px"
                : "150px"),
          }}
          src={images[currentImageIndex].dataURL}
          alt={boat.type + boat.hullNumber + "image" + currentImageIndex}
          onLoad={() => setLoading(false)}
        />
      </div>
      {images.length > 1 && (
        <IconButton
          onClick={handleNextClick}
          style={{ position: "absolute", top: "50%", right: "5px", zIndex: 1 }}
          color="primary"
          sx={{ backgroundColor: "rgba(107, 107, 107, 0.4)" }}
        >
          <ChevronRight />
        </IconButton>
      )}
      {images.length > 1 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            marginTop: "0px",
            height: "20px",
            padding: "0 3px",
          }}
        >
          {images.map((_, index) => (
            <span
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor:
                  index === currentImageIndex
                    ? palette.primary.main
                    : palette.neutral.main,
                margin: "0 5px",
                cursor: "pointer",
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Carousel;
