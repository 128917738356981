import { Route, Routes } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import LoginPage from "pages/LoginPage";
import Navbar from "components/Navbar";
import Projects from "pages/Projects";
import Footer from "components/Footer";
import Project from "pages/Project";
import Page from "pages/Page";
import CookieBanner from "components/CookieBanner";
import { resetUrl } from "state";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode), [mode])); // eslint-disable-line
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      resetUrl("https://windkraft-regional-server-076e810dcef0.herokuapp.com")
    );
  }, []); // eslint-disable-line

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={<Page pageTitle={"Home"} />} />
          <Route path="/loginAdmin" element={<LoginPage />} />

          <Route path="/about_us" element={<Page pageTitle={"Über uns"} />} />

          <Route path="/contact" element={<Page pageTitle={"Kontakt"} />} />

          <Route path="/projects">
            <Route index element={<Projects />} />
            <Route path=":projectName" element={<Project />} />
          </Route>

          <Route
            path="/legal-disclosure"
            element={<Page pageTitle={"Impressum"} />}
          />
          <Route
            path="/terms-of-service"
            element={<Page pageTitle={"TOS"} />}
          />
          <Route
            path="/privacy-policy"
            element={<Page pageTitle={"Datenschutzrichtlinie"} />}
          />
          <Route
            path="/cookie-policy"
            element={<Page pageTitle={"Cookies"} />}
          />
          <Route path="/dev" element={<Page pageTitle={"Development"} />} />
          <Route path="*" element={<Page pageTitle={"404"} />} />
        </Routes>
        <CookieBanner />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
