import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useSelector } from "react-redux";

export default function BackgroundVideo({ setVideo, content, setLoading }) {
  const amazonUrl = useSelector((state) => state.amazonUrl);
  const videoRef = useRef(null);

  useEffect(() => {
    if (content) {
      initVideoPlayer();
    }
  }, [content]); //eslint-disable-line

  const initVideoPlayer = () => {
    fetch(`${amazonUrl}/${content.video}`)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const videoPlayer = videojs(videoRef.current, {
          autoplay: content.isAutoplay,
          controls: content.isControls,
          muted: content.isMuted,
          loop: content.isLoopingVideo,
          sources: [
            {
              src: blobUrl,
              type: "video/mp4",
            },
            {
              src: blobUrl,
              type: "video/quicktime",
            },
          ],
        });

        videoPlayer.ready(() => {
          setVideo({ dataURL: blobUrl }); // Set video to the blob URL
          setLoading(false);
        });

        return () => {
          if (videoPlayer) {
            videoPlayer.dispose();
          }
          URL.revokeObjectURL(blobUrl);
        };
      })
      .catch((error) => console.error(error));
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        height: "100%",
        width: "100%",
      }}
    >
      <video
        style={{
          height: "67%",
          width: "100%",
          objectFit: "cover",
        }}
        id="videoModule"
        ref={videoRef}
        playsInline
      />
    </div>
  );
}
