import { Button, TextField, useMediaQuery } from "@mui/material";
import TextContent from "components/TextContent";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "state";

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const url = useSelector((state) => state.url);

  const login = async (e) => {
    e.preventDefault();
    setErrors("");
    try {
      const formData = {
        username,
        password,
      };

      const loggedInResponse = await fetch(`${url}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const loggedIn = await loggedInResponse.json();

      if (!loggedInResponse.ok) {
        setErrors(loggedIn.msg);
        throw new Error("Login Error");
      }
      //e.resetForm();
      if (loggedIn) {
        dispatch(
          setLogin({
            token: loggedIn.token,
          })
        );
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TextContent
      isNotMobileScreen={isNotMobileScreen}
      style={{ height: "89vh" }}
    >
      <form
        onSubmit={(e) => {
          login(e);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "1rem 10vw",
        }}
      >
        <div style={{ marginTop: "10rem" }}>Admin Login</div>

        <TextField
          id="username"
          label="Username"
          variant="standard"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="password"
          label="Password"
          variant="standard"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div style={{ color: "red" }}>{errors}</div>

        <Button type="submit">Submit</Button>
      </form>
    </TextContent>
  );
}
