import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import React, { useEffect, useState } from "react";
import TitleIcon from "@mui/icons-material/Title";
import NotesIcon from "@mui/icons-material/Notes";
import ImageIcon from "@mui/icons-material/Image";
import TheatersIcon from "@mui/icons-material/Theaters";
import DeleteDialog from "./DeleteDialog";
import CloseIcon from "@mui/icons-material/Close";
import ImageInput from "./ImageInput";
import VideoInput from "./VideoInput";
import Carousel from "./Carousel";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import ListBullet from "./ListBullet";
import VideoComponent from "./VideoComponent";
import BackgroundVideo from "./BackgroundVideo";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ImageInputRadio from "./ImageInputRadio";

export default function ContentModule({
  content,
  updateImages,
  contents,
  changeContents,
  updateOrder,
  removeContent,
  isEditing = true,
  isJustDisplaying = false,
  isEdited = false, // used to identify images/videos that have been added in the edit stage (projects that are already in the DB)
  pageTitle = "no", // if pageTitle is empty, that means if video is in orderNumber 1 it is a background video
}) {
  const { palette } = useTheme();
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState("");
  const [listPoints, setListPoints] = useState([]);
  const amazonUrl = useSelector((state) => state.amazonUrl);

  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");
  const translations = {
    listBullet: isGerman ? "Aufzählungspunkte" : "Bullet Points",
    listNumbered: isGerman ? "Nummerierte Liste" : "Numbered List",
    title: isGerman ? "Titel" : "Title",
    subtitle: isGerman ? "Untertitel" : "Subtitle",
    image: isGerman ? "Bild" : "Image",
    paragraph: isGerman ? "Absatz" : "Paragraph",
    video: isGerman
      ? "Video *Bitte achten Sie darauf, dass die Videodatei nicht zu groß ist, da keine Komprimierung erfolgt"
      : "Video *Please ensure that the video file is not too large as there is no video compression",
  };

  let updatedContents;
  const updateContent = (property, newValue) => {
    updatedContents = contents.map((contentItem) => {
      if (contentItem.orderNumber === content.orderNumber) {
        return { ...contentItem, [property]: newValue };
      }
      return contentItem;
    });
    changeContents(updatedContents);
  };

  useEffect(() => {
    isJustDisplaying && fetchImages(content.images);
  }, [content, updateImages]); // eslint-disable-line

  useEffect(() => {
    isEditing && content.images && updateContent("images", images);
  }, [images]); // eslint-disable-line

  useEffect(() => {
    isEditing && updateContent("video", video);
  }, [video]); // eslint-disable-line

  useEffect(() => {
    isEditing && content.list && updateContent("list", listPoints);
  }, [listPoints]); // eslint-disable-line

  const [loading, setLoading] = useState(true);

  //Fetch Images
  async function fetchImages(imgArray) {
    if (!imgArray) return;
    if (images.length > content.images.length) return;
    setImages([]);
    try {
      const retrievedImages = await Promise.all(
        imgArray.map(async (image, i) => {
          const response = await fetch(`${amazonUrl}/${image}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch image ${image}`);
          }
          const blob = await response.blob();
          return blobToFile(blob, image); // Assuming 'image' is a File object
        })
      );

      readAndDisplayImages(retrievedImages);
    } catch (error) {
      console.error(error);
    }

    function blobToFile(blob, fileName) {
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    }
  }

  const readAndDisplayImages = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImages((prevImages) => [
          ...prevImages,
          { file, dataURL: e.target.result },
        ]);
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (images && images[0] && images[0].file) {
      setImages(
        images.slice().sort((a, b) => {
          const indexA = parseInt(a.file.name.match(/(\d+)-index-/)[1]);
          const indexB = parseInt(b.file.name.match(/(\d+)-index-/)[1]);
          return indexA - indexB;
        })
      );
    }
  }, [loading]); // eslint-disable-line

  //Delete Module
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const deleteModule = () => {
    removeContent(content.orderNumber);
    toggleDeleteModal();
  };

  // Insert Anchor
  const anchorRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*?>(.*?)<\/a>/g;
  const renderContentWithLinks = (text) => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: text.replace(
            anchorRegex,
            `<a href="$1" style="color: ${palette.primary.main}" target="_blank">$2</a>`
          ),
        }}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        gap: isNotMobileScreen ? "2rem" : "0.5rem",
        width: "100%",
        margin: "1rem 0",
      }}
    >
      {isEditing && (
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "25px",
          }}
        >
          <IconButton
            onClick={() => {
              if (+content.orderNumber === 1) return;
              updateOrder(+content.orderNumber, +content.orderNumber - 1);
            }}
            disabled={contents.length === 1 || +content.orderNumber === 1}
          >
            <ArrowDropUpIcon />
          </IconButton>
          {content.contentType === "title" && <TitleIcon />}
          {content.contentType === "subtitle" && <TitleIcon fontSize="small" />}
          {content.contentType === "image" && <ImageIcon />}
          {content.contentType === "video" && <TheatersIcon />}
          {content.contentType === "paragraph" && <NotesIcon />}
          {content.contentType === "listBullet" && <FormatListBulletedIcon />}
          {content.contentType === "listNumbered" && <FormatListNumberedIcon />}

          {/* Input number
        <TextField
          type="number"
          variant="standard"
          value={content.orderNumber}
          onChange={(e) => {
            if (+e.target.value < 1) updateContent("orderNumber", 1);
            if (+e.target.value > +contents.length)
              updateContent("orderNumber", +contents.length);
            updateContent("orderNumber", +e.target.value);
          }}
          inputProps={{
            min: 0,
            style: { textAlign: "center" },
            inputMode: "none",
          }}
        />*/}
          <IconButton
            onClick={() => {
              if (+content.orderNumber === +contents.length) return;
              if (+content.orderNumber > +contents.length)
                return updateContent("orderNumber", +contents.length);
              updateOrder(+content.orderNumber, +content.orderNumber + 1);
            }}
            disabled={
              contents.length === 1 || +content.orderNumber === contents.length
            }
          >
            <ArrowDropDownIcon />
          </IconButton>
        </div>
      )}
      <div style={{ width: "100%" }}>
        {isEditing && (
          <Typography mt={1}>{translations[content.contentType]}</Typography>
        )}

        {content.contentType === "paragraph" && (
          <>
            {isEditing ? (
              <TextField
                sx={{ mt: 1 }}
                variant="outlined"
                value={content.content}
                onChange={(e) => updateContent("content", e.target.value)}
                fullWidth
                multiline
              />
            ) : (
              <div>
                {content.content.split("\n").map((line, index) => (
                  <Typography
                    key={index}
                    sx={{ color: palette.neutral.light, fontSize: "1.2rem" }}
                  >
                    {line}
                  </Typography>
                ))}
              </div>
            )}
          </>
        )}

        {content.contentType === "title" && (
          <>
            {isEditing ? (
              <div>
                <TextField
                  sx={{ mt: 1 }}
                  variant="outlined"
                  fullWidth
                  value={content.content}
                  onChange={(e) => updateContent("content", e.target.value)}
                  inputProps={{ style: { fontSize: "2rem" } }}
                  // InputLabelProps={{ style: { fontSize: "2rem" } }} // font size of input label
                />
              </div>
            ) : (
              <Typography variant="h2">{content.content}</Typography>
            )}
          </>
        )}

        {content.contentType === "subtitle" && (
          <>
            {isEditing ? (
              <div>
                <TextField
                  sx={{ mt: 1 }}
                  variant="outlined"
                  fullWidth
                  value={content.content}
                  onChange={(e) => updateContent("content", e.target.value)}
                  inputProps={{
                    style: { fontSize: "1.5rem", color: palette.primary.main },
                  }}
                />
              </div>
            ) : (
              <Typography variant="h3" color="primary" m="0">
                {content.content}
              </Typography>
            )}
          </>
        )}

        {content.contentType === "listBullet" && (
          <>
            {isEditing ? (
              <div>
                <ListBullet
                  content={content}
                  listPoints={listPoints}
                  setListPoints={setListPoints}
                />
              </div>
            ) : (
              <ul
                style={{
                  margin: "0",
                  color: palette.neutral.light,
                  paddingLeft: isNotMobileScreen ? "40px" : "1.5rem",
                }}
              >
                {content.list.map((bullet) => (
                  <li
                    style={{ fontSize: "1.2rem" }}
                    key={
                      bullet.pointOrder /* if these ever become movable they need a UUID key */
                    }
                  >
                    {renderContentWithLinks(bullet.textContent)}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}

        {content.contentType === "listNumbered" && (
          <>
            {isEditing ? (
              <div>
                <ListBullet
                  content={content}
                  listPoints={listPoints}
                  setListPoints={setListPoints}
                />
              </div>
            ) : (
              <ol
                style={{
                  margin: "0",
                  color: palette.neutral.light,
                  fontSize: "1.2rem",
                  paddingLeft: isNotMobileScreen ? "40px" : "1.5rem",
                }}
              >
                {content.list.map((bullet) => (
                  <li
                    key={
                      bullet.pointOrder /* if these ever become movable they need a UUID key */
                    }
                  >
                    {bullet.textContent}
                  </li>
                ))}
              </ol>
            )}
          </>
        )}

        {content.contentType === "image" && (
          <>
            {isEditing ? (
              <div style={{ margin: "1rem 0" }}>
                <ImageInput
                  images={images}
                  setImages={setImages}
                  edited={isEdited}
                />
                <ImageInputRadio
                  imgDisplaySize={content.imgDisplaySize}
                  updateContent={updateContent}
                />
              </div>
            ) : (
              <div style={{ marginTop: "1rem" }}>
                {loading && (
                  <div style={{ position: "relative", paddingTop: "56.25%" }}>
                    <Skeleton
                      variant="rounded"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                )}
                <Carousel
                  images={images}
                  loading={loading}
                  setLoading={setLoading}
                  imgDisplaySize={content.imgDisplaySize}
                />
              </div>
            )}
          </>
        )}

        {content.contentType === "video" &&
          (isEditing ? (
            <div style={{ margin: "1rem 0" }}>
              <VideoInput
                video={video}
                setVideo={setVideo}
                content={content}
                edited={isEdited}
              />
            </div>
          ) : (
            <div style={{ margin: "1rem 0" }}>
              {loading && (
                <Skeleton
                  variant={
                    pageTitle === "" && content.orderNumber === 1
                      ? "rectangular"
                      : "rounded"
                  }
                  style={{
                    position:
                      pageTitle === "" && content.orderNumber === 1
                        ? "absolute"
                        : "relative",
                    top:
                      pageTitle === "" && content.orderNumber === 1 ? "0" : "",
                    left:
                      pageTitle === "" && content.orderNumber === 1 ? "0" : "",
                    width:
                      pageTitle === "" && content.orderNumber === 1
                        ? "100vw"
                        : "60vw",
                    height:
                      pageTitle === "" && content.orderNumber === 1
                        ? "45%"
                        : "calc(60vw * (9 / 16))",
                  }}
                />
              )}
              {pageTitle === "" && content.orderNumber === 1 ? (
                <BackgroundVideo
                  video={video}
                  setVideo={setVideo}
                  content={content}
                  loading={loading}
                  setLoading={setLoading}
                />
              ) : (
                <VideoComponent
                  style={{ display: loading ? "none" : "block" }}
                  setVideo={setVideo}
                  content={content}
                  setLoading={setLoading}
                />
              )}
            </div>
          ))}

        <div>
          {(content.contentType === "video" ||
            content.contentType === "image") && (
            <>
              {isEditing ? (
                <TextField
                  variant="outlined"
                  label={
                    isGerman
                      ? "Beschreibung (optional)"
                      : "Description (optional)"
                  }
                  value={content.imgVidDescription}
                  onChange={(e) =>
                    updateContent("imgVidDescription", e.target.value)
                  }
                  sx={{ mt: 1 }}
                  fullWidth
                />
              ) : (
                <Typography sx={{ mt: 1 }} color={palette.neutral.medium}>
                  {content.imgVidDescription}
                </Typography>
              )}
            </>
          )}

          {content.contentType === "video" && isEditing && (
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={content.isAutoplay}
                    onChange={(e) =>
                      updateContent("isAutoplay", e.target.checked)
                    }
                    name={"isAutoplay"}
                  />
                }
                label={isGerman ? "Automatische Wiedergabe" : "Autoplay"}
              />
              <Tooltip
                title={
                  isGerman
                    ? "Bitte beachten Sie, dass Nutzer das Video nicht abspielen können, wenn keine Videosteuerelemente vorhanden sind, es sei denn, die automatische Wiedergabe ist aktiviert"
                    : "Please note that if there are no video controls the user cannot play the video, unless autoplay is enabled"
                }
                placement="bottom"
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                disableFocusListener
                open={!content.isControls && !content.isAutoplay}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={content.isControls}
                      onChange={(e) => {
                        updateContent("isControls", e.target.checked);
                      }}
                      name={"isControls"}
                    />
                  }
                  label={isGerman ? "Bedienelement" : "Controls"}
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={content.isLoopingVideo}
                    onChange={(e) =>
                      updateContent("isLoopingVideo", e.target.checked)
                    }
                    name={"isLoopingVideo"}
                  />
                }
                label={isGerman ? "Schleife" : "Loop"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={content.isMuted}
                    onChange={(e) => updateContent("isMuted", e.target.checked)}
                    name={"isMuted"}
                  />
                }
                label={isGerman ? "Stumm" : "Mute"}
              />
            </FormGroup>
          )}
        </div>
      </div>
      {isEditing && (
        <div style={{ alignSelf: "center" }}>
          <IconButton onClick={toggleDeleteModal}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <DeleteDialog
        isDeleteModal={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        del={deleteModule}
        title={isGerman ? "Löschung bestätigen" : "Confirm Deletion"}
        dialogContent={
          isGerman
            ? `Sind Sie sicher, dass Sie die Komponente ${content.contentType} (${content.orderNumber}. Komponente) löschen möchten?`
            : `Are you sure you want to delete this ${content.contentType} (${content.orderNumber}. component)?`
        }
        delButton={isGerman ? "Löschen" : "Delete"}
      />
    </div>
  );
}
