import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "state";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function Footer() {
  const token = useSelector((state) => state.token);
  const mode = useSelector((state) => state.mode);
  const language = useSelector((state) => state.language);
  const { palette } = useTheme();
  const dispatch = useDispatch();

  return (
    <footer style={{ marginTop: "auto" }}>
      <div
        style={{
          backgroundColor:
            mode === "dark" ? palette.neutral.dark : palette.neutral.light,
          minHeight: "16px",
        }}
      ></div>
      <div
        className="footer"
        style={{
          backgroundColor:
            mode === "dark" ? palette.neutral.main : palette.neutral.lighter,
          minHeight: "6vh",
        }}
      >
        <div className="footerContainer">
          <NavLink className="footerLinks" to="/">
            Home
          </NavLink>
          <NavLink className="footerLinks" to="/about_us">
            {language === "german" ? "Über uns" : "About us"}
          </NavLink>
          <NavLink className="footerLinks" to="/contact">
            {language === "german" ? "Kontakt" : "Contact"}
          </NavLink>
          <NavLink className="footerLinks" to="/projects">
            {language === "german" ? "Projekte" : "Projects"}
          </NavLink>
          {!token ? (
            <NavLink className="footerLinks" to="/loginAdmin">
              {language === "german" ? "Login" : "Login"}
            </NavLink>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className="footerLinks"
              onClick={() => dispatch(setLogout())}
            >
              Logout
            </div>
          )}
        </div>
        <div style={{ height: "1rem" }}></div>
        <div className="footerContainer">
          <NavLink className="policy" to="/legal-disclosure">
            {language === "german" ? "Impressum" : "Legal Disclosure"}
          </NavLink>
          <NavLink className="policy" to="/cookie-policy">
            {language === "german" ? "Cookie-Richtlinie" : "Cookie Policy"}
          </NavLink>
          <NavLink className="policy" to="/privacy-policy">
            {language === "german" ? "Datenschutzrichtlinie" : "Privacy Policy"}
          </NavLink>
          <NavLink className="policy" to="/terms-of-service">
            {language === "german" ? "Nutzungsbedingungen" : "Terms of Service"}
          </NavLink>
          <NavLink className="policy" to="https://pvgreen.de/">
            <div style={{ display: "flex", gap: "1rem" }}>
              <div style={{ alignSelf: "center" }}>Kooperationspartner:</div>
              <img
                style={{ height: "1.6rem", alignSelf: "center" }}
                src="/assets/pvgreen.svg"
                alt="PVGreen Logo"
              />
            </div>
          </NavLink>
        </div>
      </div>
    </footer>
  );
}
