import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { KeyboardArrowDown } from "@mui/icons-material";
import TitleIcon from "@mui/icons-material/Title";
import NotesIcon from "@mui/icons-material/Notes";
import ImageIcon from "@mui/icons-material/Image";
import TheatersIcon from "@mui/icons-material/Theaters";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export default function DropDownComponents({ addContent }) {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const addAndClose = (type) => {
    addContent(type);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{ mt: 1 }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        fullWidth
      >
        {isGerman ? "Komponente hinzufügen" : "Add Component"}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList sx={{ width: isNotMobileScreen ? "60vw" : "92vw" }}>
          <MenuItem onClick={() => addAndClose("title")}>
            <ListItemIcon>
              <TitleIcon />
            </ListItemIcon>
            <ListItemText>{isGerman ? "Titel" : "Title"}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => addAndClose("subtitle")}>
            <ListItemIcon>
              <TitleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{isGerman ? "Untertitel" : "Subtitle"}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => addAndClose("image")}>
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText>{isGerman ? "Bild(er)" : "Image(s)"}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => addAndClose("video")}>
            <ListItemIcon>
              <TheatersIcon />
            </ListItemIcon>
            <ListItemText>Video</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => addAndClose("paragraph")}>
            <ListItemIcon>
              <NotesIcon />
            </ListItemIcon>
            <ListItemText>{isGerman ? "Absatz" : "Paragraph"}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => addAndClose("listBullet")}>
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText>
              {isGerman ? "Aufzählungspunkte" : "Bullet Points"}
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => addAndClose("listNumbered")}>
            <ListItemIcon>
              <FormatListNumberedIcon />
            </ListItemIcon>
            <ListItemText>
              {isGerman ? "Nummerierte Liste" : "Numbered List"}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
