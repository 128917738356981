import {
  Button,
  Divider,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TextContent from "components/TextContent";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { v4 as uuidv4 } from "uuid";
import DeleteDialog from "components/DeleteDialog";
import { unlinkProject } from "state";
import FormattedDate from "components/FormattedDate";
import ContentModule from "components/ContentModule";
import DropDownComponents from "components/DropDownComponents";

export default function Project() {
  //currently working on front end presentation of contents, backend image and video saving does not work yet

  const { palette } = useTheme();
  const params = useParams();
  const paramProjectName = params.projectName;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const token = useSelector((state) => state.token);
  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");
  const [message, setMessage] = useState("");
  const url = useSelector((state) => state.url);

  useEffect(() => {
    document.title = `WKR | ${paramProjectName}`;
    findProject();
  }, []); // eslint-disable-line

  const [project, setProject] = useState({});
  const [originalProject, setOriginalProject] = useState({});
  const [editing, setEditing] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);

  // Edit State
  const [updatedAt, setUpdatedAt] = useState("");

  // Edit State

  const [isLoading, setLoading] = useState(false);

  async function findProject() {
    setLoading(true);
    try {
      const response = await fetch(
        `${url}/projects/project/${paramProjectName}`,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.message) return setMessage(data.message);
        setProject(data);
        setOriginalProject(data);
      } else {
        const data = await response.json();
        console.log(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Edit project

  const addContent = (type) => {
    const uniqueId = uuidv4();
    let newComponent = {
      uuid: uniqueId,
      orderNumber: project.contents.length + 1,
      contentType: type,
      content: "",
    };

    if (type === "video" || type === "image") {
      newComponent.imgVidDescription = "";
      newComponent.imgDisplaySize = "large";
    }

    if (type === "image") {
      newComponent.images = [];
    }

    if (type === "video") {
      newComponent.isAutoplay = false;
      newComponent.isControls = true;
      newComponent.isLoopingVideo = false;
      newComponent.isMuted = false;
    }

    if (type === "listBullet" || type === "listNumbered") {
      newComponent.list = [];
    }

    setProject({ ...project, contents: [...project.contents, newComponent] });
  };

  const changeContents = (updatedContents) => {
    setProject({ ...project, contents: updatedContents });
  };

  const updateOrder = (oldNumber, newNumber) => {
    const updatedContents = project.contents.map((content) => {
      if (content.orderNumber === oldNumber) {
        return { ...content, orderNumber: newNumber };
      } else if (content.orderNumber === newNumber) {
        return { ...content, orderNumber: oldNumber };
      }
      return content;
    });

    changeContents(updatedContents);
  };

  const removeContent = (orderNumberToRemove) => {
    const updatedContents = project.contents
      .filter((content) => content.orderNumber !== orderNumberToRemove)
      .map((content) => {
        if (content.orderNumber > orderNumberToRemove) {
          return { ...content, orderNumber: content.orderNumber - 1 };
        }
        return content;
      });

    changeContents(updatedContents);
  };

  useEffect(() => {
    setUpdatedAt(project.updatedAt);
  }, [project]);

  function deepEqual(obj1, obj2) {
    // If both objects are not objects, compare them directly
    if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
      return obj1 === obj2;
    }

    // If the number of keys in both objects are different, they're not equal
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check each key-value pair recursively
    for (const key of keys1) {
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    // If all key-value pairs match, the objects are equal
    return true;
  }

  const [updateImages, setUpdateImages] = useState(false);

  async function patchProject() {
    if (
      deepEqual(project, originalProject) &&
      project.projectName !== originalProject.projectName
    )
      return setEditing(false);

    let contentsForServer = project;
    const formData = new FormData();

    formData.append("_id", contentsForServer._id);
    formData.append("projectName", contentsForServer.projectName);

    // Comparing images
    function dataURLtoFile(dataURL, filename) {
      const arr = dataURL.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    function getRandomUUID() {
      return uuidv4();
    }

    let imagesToDelete = [];
    let videosToDelete = [];

    imagesToDelete = findImagesToDelete(
      contentsForServer.contents,
      originalProject.contents
    );

    contentsForServer.contents.forEach((content) => {
      if (content.images) {
        content.images.forEach((image, imageIndex) => {
          if (image.cropped) {
            const croppedImage = dataURLtoFile(
              image.dataURL,
              `${
                content.orderNumber
              }-contentOrder-${imageIndex}-index-${getRandomUUID()}-${Date.now()}.${
                image.cropped
              }`
            );
            formData.append("images", croppedImage);
            content.images[imageIndex] = croppedImage.name;
          }
          if (image.edited && image.cropped === undefined) {
            const file = image.file;
            const extension = file.name.split(".").pop();
            const newName = `${
              content.orderNumber
            }-contentOrder-${imageIndex}-index-${getRandomUUID()}-${Date.now()}.${extension}`;

            const newFile = new File([file], newName, { type: file.type });
            formData.append("images", newFile);
            content.images[imageIndex] = newName;
          }
          if (
            image.edited === undefined &&
            image.cropped === undefined &&
            image.file
          ) {
            content.images[imageIndex] = image.file.name;
          }
        });
      }
      if (content.video) {
        if (content.video.edited) {
          const file = content.video.file;
          const extension = file.name.split(".").pop();
          const newName = `${
            content.orderNumber
          }-contentOrder-${getRandomUUID()}-${Date.now()}.${extension}`;

          const newFile = new File([file], newName, { type: file.type });
          formData.append("videos", newFile);
          content.video = newName;
          const originalVideo = originalProject.contents.find(
            (cont) => cont.uuid === content.uuid
          );
          if (originalVideo) videosToDelete.push(originalVideo.video);
        }
      }
    });

    // If entire module got deleted
    originalProject.contents.forEach((content) => {
      if (
        !contentsForServer.contents.some(
          (editedContent) => editedContent.uuid === content.uuid
        )
      ) {
        if (content.images) {
          content.images.forEach((image) => {
            imagesToDelete.push(image);
          });
        }
        if (content.video) {
          videosToDelete.push(content.video);
        }
      }
    });

    function findImagesToDelete(editedContents, originalContents) {
      editedContents.forEach((editedContent) => {
        const originalContent = originalContents.find((originalContent) => {
          return originalContent.uuid === editedContent.uuid;
        });

        if (!originalContent) {
          return;
        }

        let originalImageObjects = [];
        if (originalContent.images) {
          originalImageObjects = Array.from(originalContent.images).map(
            (imageName) => ({
              name: imageName,
              hasImage: false,
            })
          );
        }

        editedContent.images &&
          editedContent.images.forEach((editedImage) => {
            originalImageObjects.forEach((obj) => {
              if (editedImage.file && obj.name === editedImage.file.name) {
                obj.hasImage = true;
              }
              if (
                editedImage.file &&
                obj.name === editedImage.file.name &&
                editedImage.cropped
              ) {
                obj.hasImage = false;
              }
              if (!editedImage.file && !editedContent.edited) {
                obj.hasImage = true;
              }
            });
          });

        originalImageObjects.forEach((obj) => {
          if (obj.hasImage === false) {
            imagesToDelete.push(obj.name);
          }
        });
      });
      return imagesToDelete;
    }

    const imagesToDeleteSerialised = JSON.stringify(imagesToDelete);
    formData.append("imagesToDelete", imagesToDeleteSerialised);

    const videosToDeleteSerialised = JSON.stringify(videosToDelete);
    formData.append("videosToDelete", videosToDeleteSerialised);

    contentsForServer.contents.forEach((content) => {
      if (content.images && content.images.length === 0) {
        delete content.images;
      }
    });

    const contentsSerialised = JSON.stringify(contentsForServer.contents);
    formData.append("contents", contentsSerialised);

    try {
      const response = await fetch(`${url}/projects/project/patch`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        const updatedProject = {
          ...contentsForServer,
          updatedAt: Date.now(),
        };
        setProject(updatedProject);
        setOriginalProject(updatedProject);
        if (originalProject.projectName !== project.projectName)
          navigate(`/projects/${project.projectName}`);
        setEditing(false);
        setUpdateImages(!updateImages);
      } else {
        const data = await response.json();
        console.log(data.error);
        if (data.error.includes("E11000")) {
          setDuplicateError(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Delete Project

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  async function deleteProject() {
    try {
      const response = await fetch(`${url}/projects/project/delete`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId: project._id,
        }),
      });

      if (response.ok) {
        toggleDeleteModal();
        dispatch(
          unlinkProject({
            projectId: project._id,
          })
        );
        navigate("/projects");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <TextContent isNotMobileScreen={isNotMobileScreen}>
      {message && <div key={"error"}>Error: {message}</div>}

      {!editing && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: [isNotMobileScreen ? "center" : "end"],
              gap: "1rem",
              marginBottom: "0rem",
            }}
          >
            {!message &&
              (isNotMobileScreen ? (
                <div>
                  <h2 style={{ margin: "0" }}>{project.projectName}</h2>
                  <h4 style={{ margin: "0", color: palette.primary.main }}>
                    Windkraft Regional
                  </h4>
                </div>
              ) : (
                <h4 style={{ margin: "0" }}>{project.projectName}</h4>
              ))}
            {!message && token && (
              <div>
                <IconButton
                  color="primary"
                  style={{ position: "relative" }}
                  onClick={() => setEditing(true)}
                >
                  <EditIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <FormattedDate
              date={updatedAt}
              text={
                language === "german"
                  ? "Letzte Aktualisierung: "
                  : "Last updated: "
              }
            />
          </div>
        </>
      )}

      {!editing && (
        <div style={{ marginBottom: "1rem" }}>
          <Divider />
        </div>
      )}

      {editing &&
        (isNotMobileScreen ? (
          <h3 style={{ margin: "0" }}>Edit {originalProject.projectName}</h3>
        ) : (
          <h4 style={{ margin: "0" }}>Edit {originalProject.projectName}</h4>
        ))}
      {editing && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <div style={{ display: "flex", gap: "1rem" }}>
              <TextField
                label={isGerman ? "Projektname" : "Project Name"}
                name="projectName"
                variant="standard"
                fullWidth
                value={project.projectName}
                onChange={(e) =>
                  setProject({ ...project, projectName: e.target.value })
                }
              />
            </div>
            {duplicateError && (
              <div style={{ color: "red" }}>
                {isGerman
                  ? `Fehler! Der Projektname "${project.projectName}" ist bereits vergeben.`
                  : `Error! The project name "${project.projectName}" is already taken`}
              </div>
            )}
            <DropDownComponents addContent={addContent} />
            <div style={{ marginTop: "2rem" }}></div>
          </div>
        </>
      )}

      {project.contents &&
        project.contents
          .slice()
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((content) => (
            <ContentModule
              key={content.uuid}
              content={content}
              updateImages={updateImages}
              contents={project.contents}
              changeContents={changeContents}
              updateOrder={updateOrder}
              removeContent={removeContent}
              isEditing={editing}
              isJustDisplaying={!editing}
              isEdited={true}
            />
          ))}
      {editing && (
        <>
          <div style={{ marginTop: "1rem", display: "flex", gap: "1rem" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setEditing(false);
                setProject(originalProject);
              }}
              fullWidth
              endIcon={<CloseIcon />}
            >
              {isGerman ? "Abbruch" : "Cancel"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                patchProject();
              }}
              fullWidth
              endIcon={<CheckIcon />}
            >
              {isGerman ? "Aktualisieren" : "Update"}
            </Button>
          </div>
          <Button
            variant=""
            onClick={() => {
              toggleDeleteModal();
            }}
            fullWidth
            endIcon={<CloseIcon />}
            color="error"
            sx={{ marginTop: "2rem" }}
          >
            {isGerman ? "Projekt Löschen" : "Delete Project"}
          </Button>
          <DeleteDialog
            isDeleteModal={isDeleteModalOpen}
            toggleModal={toggleDeleteModal}
            del={deleteProject}
            title={isGerman ? "Löschen Bestätigen" : "Confirm Deletion"}
            dialogContent={
              isGerman
                ? `Sind Sie sicher, dass Sie das Projekt löschen möchten: ${project.projectName}?`
                : `Are you sure you want to delete the Project: ${project.projectName}?`
            }
            delButton={isGerman ? "Löschen" : "Delete"}
          />
        </>
      )}
    </TextContent>
  );
}
